import PropTypes from 'prop-types'

import styles from './TwoThirds.module.scss'
import { ClassNames } from 'services/classname.service'

const TwoThirds = ({ children, largeSide = 'left', className = '', gridGap = 48 }) => {
  return (
    <section
      className={ClassNames([
        styles[`ds-two-thirds`],
        styles[`ds-two-thirds--large-side-${largeSide}`],
        `display-grid ${className}`
      ])}
      style={{ '--gap': `${gridGap}px` }}
    >
      {children}
    </section>
  )
}

TwoThirds.propTypes = {}

export default TwoThirds
