import PropTypes from 'prop-types'

import styles from './IconTextBlockLayout.module.scss'
import { ClassNames } from 'services/classname.service'

const IconTextBlockLayout = ({ className, children, largeSide = 'left', gridGap = 144 }) => {
  return (
    <section
      className={ClassNames([
        styles['icon-text-block-layout'],
        styles[`large-side-${largeSide}`],
        `display-grid  ${className}`
      ])}
      style={{ '--gap': `${gridGap}px` }}
    >
      {children}
    </section>
  )
}

IconTextBlockLayout.propTypes = {
  largeSide: PropTypes.string
}

export default IconTextBlockLayout
