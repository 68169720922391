import PropTypes from 'prop-types'

import styles from './SupportingContent.module.scss'
import SixtyFourty from '../SixtyFourty'
import TwoUp from '../TwoUp'
import { ClassNames } from '../../services/classname.service'
import Container from '../../containers/Container'
import TwoThirds from '../TwoThirds'
import SingleColumn from '../SingleColumn'
import IconTextBlockLayout from '../../../../layouts/IconTextBlockLayout'

const Layouts = Object.freeze({
  sixtyfourty: SixtyFourty,
  fiftyfifty: TwoUp,
  twothirds: TwoThirds,
  full: SingleColumn,
  '60:40': SixtyFourty,
  '50:50': TwoUp,
  '66:33': TwoThirds,
  '40:60': SixtyFourty,
  '33:66': TwoThirds,
  iconList: IconTextBlockLayout
})

const LargeSide = Object.freeze({
  sixtyfourty: 'left',
  fiftyfifty: null,
  twothirds: 'left',
  full: null,
  '60:40': 'left',
  '50:50': null,
  '66:33': 'left',
  '40:60': 'right',
  '33:66': 'right',
  iconList: 'right'
})

const Gap = Object.freeze({
  sixtyfourty: 144,
  fiftyfifty: 144,
  twothirds: 144,
  full: null,
  '60:40': 144,
  '50:50': 144,
  '66:33': 144,
  '40:60': 144,
  '33:66': 144,
  iconList: 144
})

const SupportingContent = ({
  className = '',
  type = 'fiftyfifty',
  children,
  containerOptions = {},
  id,
  ...theRest
}) => {
  const Layout = Layouts[type]
  const largeSide = LargeSide[type]
  if (!theRest.gridGap) {
    theRest.gridGap = Gap[type]
  }
  return (
    <Container {...containerOptions} id={id}>
      <Layout
        className={ClassNames([styles['ds-supporting-content'], className])}
        largeSide={largeSide}
        {...theRest}
      >
        {children}
      </Layout>
    </Container>
  )
}

SupportingContent.propTypes = {}

export default SupportingContent
