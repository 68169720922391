import PropTypes from 'prop-types'

import styles from './TextBlock.module.scss'
import { ClassNames } from 'services/classname.service'
import Heading from 'basics/Heading'
import CtaWidgets from 'features/CtaWidgets'

const TextBlock = ({ className, align, data = {}, children }) => {
  const { headline, subheadline, body, ctas, verticalAlignment } = data
  if (!align) {
    align = verticalAlignment && verticalAlignment.length > 0 ? verticalAlignment[0].name : 'center'
  }
  return (
    <div
      className={ClassNames([
        styles['ds-text-block'],
        className,
        `justify-content-${align}`,
        'display-flex flex-dir-col md-mb-5'
      ])}
    >
      {headline && (
        <Heading level={2} className="mb-3 h3">
          {headline}
        </Heading>
      )}
      {subheadline && <p className="intro-paragraph">{subheadline}</p>}
      {body && (
        <div
          className={styles['ds-text-block__body']}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      )}
      {children}

      <CtaWidgets className={ClassNames([`mt-3 ${ctas?.length > 1 && ('grid-two-col-row')}`])} ctaWidgets={ctas} />
    </div>
  )
}

TextBlock.propTypes = {
  align: PropTypes.oneOf(['center', 'flex-start', 'flex-end'])
}

export default TextBlock
