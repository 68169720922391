import PropTypes from 'prop-types'

import styles from './PullQuote.module.scss'
import SixtyFourty from '../../layouts/SixtyFourty'
import Container from '../../containers/Container'
import GridContainer from '../../containers/GridContainer/GridContainer'
import { ClassNames } from '../../services/classname.service'

const PullQuote = ({ className, children, showIcon = true, color = 'plum' }) => {
  return (
    <Container className={showIcon ? '' : 'ml-0'}>
      <GridContainer className={styles['ds-pull-quote']}>
        {showIcon && (
          <div
            className={ClassNames([
              styles['ds-pull-quote__image'],
              styles[`ds-pull-quote__image--${color}`],
              className
            ])}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.1 366.38" width="70">
              <g>
                <path
                  d="M644.58,584.24H445c-8.92-3.24-12-9.78-12-19,.17-61.33.07-122.66.14-184a33.47,33.47,0,0,1,1.32-10.3Q458,300.05,481.73,229.27c2.43-7.28,7.45-11.28,15.2-11.32,15.8-.08,31.6-.17,47.4.05,8.52.13,13.89,5.39,14.87,13.8a54.51,54.51,0,0,1,.2,6V362h6.26c23.62,0,47.24.19,70.86-.09,9.24-.11,15.78,3,19,11.94V573.27Z"
                  transform="translate(-144.42 -217.86)"
                />
                <path
                  d="M355.15,584.24H155.54c-8.65-4-11.16-10.83-11.12-20.1.27-61,.11-122,.21-183a38.06,38.06,0,0,1,1.78-11.71Q169.33,300,192.56,230.69c3.15-9.44,7.68-12.69,17.72-12.74,13.81-.06,27.61,0,41.41,0s19,5,19,18.87V362H348.3c13.25,0,18.74,5.52,18.74,18.83,0,61.49-.08,123,.09,184.46C367.13,574.47,364.06,581,355.15,584.24Z"
                  transform="translate(-144.42 -217.86)"
                />
              </g>
            </svg>
          </div>
        )}
        <div className={styles['ds-pull-quote__text']}>{children}</div>
      </GridContainer>
    </Container>
  )
}

PullQuote.propTypes = {
  color: PropTypes.oneOf(['blue', 'orange', 'plum', 'green'])
}

export default PullQuote
