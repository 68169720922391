import styles from './PullQuote.module.scss'
import { ClassNames } from 'services/classname.service'

const Quote = ({ className, highlight = false, children }) => {
  return (
    <div
      className={ClassNames([
        styles['ds-quote'],
        highlight ? styles['ds-quote--highlight'] : '',
        className,
        'font-style-italic'
      ])}
    >
      {children}
    </div>
  )
}

export default Quote
