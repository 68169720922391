import PropTypes from 'prop-types'

import styles from './SquareImage.module.scss'
import Image from '../Image'
import { ClassNames } from 'services/classname.service'
import { ImageConfig } from 'services/image.service'
import { PickFirst } from 'services/utils'

const getSource = ({ type, src, image, props = {}, data }) => {
  if (type === 'cloudinary') {
    const fileName = image && image.length > 0 ? image[0]?.fileName : src?.split('/').pop()
    return `${ImageConfig.prefix.cloudinary(props)}${fileName}`
  }
  return image && image.length > 0 ? `${image[0].id}/${image[0]?.fileName}` : src
}

const SquareImage = ({
  className,
  src,
  type = 'cloudinary',
  gravity = 'face',
  hasOverlay = true,
  data = {},
  props = {}
}) => {
  const imageProps = {
    gravity,
    width: '538',
    crop: 'fill',
    ...props
  }
  const { image = [], isSquare = true } = data || {}
  const metadata = PickFirst(image)?.metadata || {}
  const source = getSource({ type, src, image, props: imageProps })
  if (isSquare) {
    imageProps.width = ImageConfig.squareDimensions.width
    imageProps.height = ImageConfig.squareDimensions.height
  }

  return (
    <Image
      className={ClassNames([
        isSquare === true && hasOverlay ? styles[`ds-image--square`] : '',
        'md-mb-5',
        className
      ])}
      src={source}
      imageProps={imageProps}
      type={type}
      alt={metadata.alt || ''}
    />
  )
}

SquareImage.propTypes = {
  type: PropTypes.oneOf(['cloudinary', 'local']),
  src: PropTypes.string
}

export default SquareImage
