import PropTypes from 'prop-types'

import styles from './TestimonialContentFeature.module.scss'
import PullQuote from 'features/PullQuote'
import Quote from 'features/PullQuote/Quote'
import Container from 'containers/Container'
import Heading from 'basics/Heading'
import { PickFirst } from 'services/utils'
import Colors from 'design-system/src/constants/colors'

const TestimonialContentFeature = ({ className, data }) => {
  if (!data) {
    return null
  }
  const { testimonials = [] } = data || {}
  const { quoteFull, quoteShort, quoteAttribution, color } =
    testimonials.length > 0 ? testimonials[0] : {}
  const attribution = PickFirst(quoteAttribution) || {}
  const company = PickFirst(attribution.company) || {}

  return (
    <Container className={className}>
      <PullQuote
        color={Colors[color && color.length > 0 ? color[0].shortName : 'plum']}
        showIcon={data?.showIcon !== false ? true : false}
      >
        {data?.headline && (
          <p className="h4 mb-2">
            {data.headline}
          </p>
        )}
        {quoteShort && (
          <Quote highlight={true} className="mb-3">
            {quoteShort}
          </Quote>
        )}
        {quoteFull && <Quote>"{quoteFull}"</Quote>}
        <div className="attribution mt-3">
          <p className="mb-0">
            <strong>
              {attribution.firstName} {attribution.lastName}
            </strong>
          </p>
          <p>
            {attribution && attribution.title}
            {attribution && attribution.title && company && ', '}
            {company && company.name}
          </p>
        </div>
      </PullQuote>
    </Container>
  )
}

TestimonialContentFeature.propTypes = {}

export default TestimonialContentFeature
