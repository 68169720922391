import React, { Children } from 'react'
import PropTypes from 'prop-types'

import styles from './CtaBanner.module.scss'
import { ClassNames } from '../../services/classname.service'
import { ExtractImage } from 'design-system/src/services/data.service'
import { ImageConfig } from 'services/image.service'
import CtaWidgets from 'features/CtaWidgets'

const Themes = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  primaryLight: 'plum',
  plum: 'plum',
  blue: 'blue',
  green: 'green',
  orange: 'orange'
}

const Types = {
  default: 'default',
  Default: 'default',
  backgroundImage: 'background-image',
  withCutout: 'has-cutout',
  'With cutout': 'has-cutout',
  'Background image': 'background-image'
}

const CtaBanner = ({
  className,
  title,
  link,
  actionText,
  actionLevel = 'primary',
  children,
  theme = 'primary',
  backgroundImage,
  cutoutImage,
  data = {}
}) => {
  if (!title && Object.keys(data).length == 0) {
    return null
  }
  const {
    headline,
    subheadline,
    body,
    color = [],
    ctaWidgets = [],
    type = 'default',
    image,
    imagePosition
  } = data || {}
  const displayColor = color?.length > 0 ? color[0].shortName : 'plum'
  const internalType = Types[type]
  const imagePath = ExtractImage(image || [])
  let prefix = ImageConfig.prefix.cloudinary({})
  return (
    <section
      className={ClassNames([
        styles['ds-cta-banner'],
        'mx-auto',
        className,
        styles[`ds-cta-banner--${Themes[displayColor]}`],
        backgroundImage ? styles[`ds-cta-banner--image`] : '',
        styles[`ds-cta-banner--${internalType}`]
      ])}
      style={{
        backgroundImage:
          internalType === 'background-image' && imagePath?.fileName
            ? `url(${`${ImageConfig.prefix.cloudinary({ width: '900' })}${imagePath?.fileName}`})`
            : null
      }}
    >
      <div
        className={ClassNames([
          styles['ds-cta-banner__content'],
          imagePosition ? styles[`right`] : ''
        ])}
      >
        {title && internalType !== 'has-cutout' && <h2 className="h3 mb-5">{title}</h2>}

        {title && internalType === 'has-cutout' && <h2 className="h3 mb-5">{title}</h2>}

        {headline && internalType !== 'has-cutout' && (
          <>
            <h2 className="h3 mb-5">{headline}</h2>
          </>
        )}

        {headline && internalType === 'has-cutout' && (
          <>
            <h2 className="h3 mb-5">{headline}</h2>
          </>
        )}

        {subheadline && (
          <p className={ClassNames([styles['intro-paragraph'], 'intro-paragraph'])}>
            {subheadline}
          </p>
        )}

        {body && (
          <div
            className={ClassNames([styles['intro-paragraph'], 'intro-paragraph'])}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}

        {children}

        <CtaWidgets
          className="mt-5"
          ctaWidgets={ctaWidgets}
          type={internalType === 'background-image' ? 'primaryLight' : 'secondary'}
        />
      </div>
      {internalType === 'has-cutout' && (
        <img
          loading="lazy"
          src={`${prefix}${image[0].image[0]?.fileName}`}
          alt={`${image[0].image[0]?.metadata?.alt || ''}`.trim()}
          className={ClassNames([styles['cutout'], imagePosition ? styles[`right`] : ''])}
        />
      )}
      <div className={styles['clearfix']}></div>
    </section>
  )
}

CtaBanner.propTypes = {
  theme: PropTypes.oneOf(Object.keys(Themes)),
  type: PropTypes.oneOf(Object.keys(Types)),
  backgroundImage: PropTypes.string,
  cutoutImage: PropTypes.string,
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  body: PropTypes.string,
  ctas: PropTypes.array
}

export default CtaBanner
