import PropTypes from 'prop-types'
import GridContainer from '../../containers/GridContainer'

import './TwoUp.module.scss'
import { ClassNames } from '../../services/classname.service'

function TwoUp({ children, className, imageSide = 'right', gridGap = 0 }) {
  if (children.length > 2) {
    console.warn('You have too many children in this component')
  }
  return (
    <section className={ClassNames([`ds-two-up`, className])}>
      <GridContainer gridSize={2} gridGap={gridGap}>
        {children}
      </GridContainer>
    </section>
  )
}

TwoUp.propTypes = {
  imageSide: PropTypes.oneOf(
    Object.keys({
      left: 'left',
      right: 'right'
    })
  )
}

export default TwoUp
